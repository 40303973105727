.csvLink {
    img {
        width: 4rem;
    }

    .smallBtn {
        width: 5rem;
        border-radius: 5px;
        border: 1px solid #4baf4f;
        margin: auto;
        transition: all .2s;

        img {
            width: 5rem;
        }

        &:hover {
            transition: all .2s;
            box-shadow: 1px 1px 14px #808080d1;
        }
    }
}