.userInDetail {
    .contentFilterSearch {
        width: 15rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        padding: 1.5rem;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;

        .itemForSearch {
            margin-bottom: 0.7rem;
        }

        .buttonDetails {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            button {
                margin-bottom: 1rem;
                min-width: 12rem;
            }
        }
    }

    .contentUser {
        height: unset;
        margin-left: 16rem;

        .myNavTabs {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 2rem 0;

            .navIcon {
                text-align: center;
                padding: 1rem 1.5rem;

                &.active {
                    border: 2px solid #114578;
                    background-color: white;
                    border-radius: 25px;

                    .labelNav {
                        font-weight: bold;
                    }
                }

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                .labelNav {
                    margin: 0;
                }

                .fa-play-circle {
                    color: #37c3ec;
                    font-size: 30px;
                }

                .fa-key {
                    color: #054a9f;
                    font-size: 30px;
                }

                .fa-chromecast {
                    color: #4548fc;
                    font-size: 30px;
                }

                .fa-gift {
                    color: #37c3ec;
                    font-size: 30px;
                }

                .fa-money-bill {
                    color: #5ad05a;
                    font-size: 30px;
                }

                .fa-user-shield {
                    color: #ff5a5a;
                    font-size: 30px;
                }

                .fa-user-lock {
                    color: #ec9737;
                    font-size: 30px;
                }
            }
        }
    }
}

@media(max-width:768px) {
    .userInDetail {
        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;

            .buttonDetails {
                button {
                    min-width: unset;
                    margin: 1rem 0.3rem !important;
                }
            }
        }

        .contentUser {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
            overflow-y: unset;

            .myNavTabs {
                justify-content: center;
                flex-wrap: wrap;
            }

            .titleMobile {
                padding: 0 0.5rem;
            }
        }
    }
}