.expanableComponent {
    margin: 0 !important;
    padding: 25px;

    .btn-primary {
        margin-bottom: 15px;
    }
}

.expanableComponentUser {
    margin: 0 60px 10px 60px !important;
    padding: 15px !important;
    border: 1px solid #72aae4;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;

    .row.title {
        margin-bottom: 0.5rem;
        text-align: center;
        font-weight: bold;
    }

    .blockHeight {
        max-height: 30rem;
        overflow-y: auto;
        margin: 0;
        padding: 0;
        overflow-x: hidden;

        .row.subList {
            font-size: 0.85rem;
            text-align: center;

            .col {
                padding-top: 2px;
                padding-bottom: 2px;
            }

            .icon {
                padding-top: 8px;
            }

            &:nth-child(even) {
                background-color: #bbe9ff75;
            }

            &:hover {
                background-color: #1b76d357;
                cursor: default !important;
            }

            .top-10 {
                padding-top: 10px;
            }
        }

        .row.payment {
            &:hover {
                cursor: inherit;
            }
        }
    }

    .btn-primary {
        margin-bottom: 15px;
    }

    .info {
        font-style: italic;
        font-size: 0.8rem;
        margin-bottom: 10px;
    }
}

// end expanableComponentUser

.iconTrial,
.iconWait,
.iconLifetime,
.iconRefund,
.iconPartialRefund,
.iconSub,
.iconNotSub {
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconTrial {
    color: #f35555;
}

.iconRefund {
    color: #fa3a3a;
}

.iconLifetime {
    color: #00d200;
}

.iconSub {
    color: #1b76d3;

}

.iconNotSub,
.iconWait,
.iconPartialRefund {
    color: orange;
}