.titleButton {
    margin-bottom: 2%;
}

.buttonsGroup {
    margin: 1% 0 2% 0;
    button {
        font-size: 20px;
        &:nth-child(1){
            border-radius: 5px 0 0 5px;
        }
        &:nth-child(2){
            border-radius: 0 5px 5px 0;
        }
    }
}

.changeLog {
    border: 2px solid #1b76d3;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0px grey;
    margin: auto;
    width: 65%;
    padding: 1% 4% 1% 4%;
    transition: .2s all;
    background: white;
    position: relative;

    .row {
        margin: 0 0 2% 0;
        padding-top: 2%;
        &:nth-child(even) {
            background-color: #dedede7a;
        }

        .btnEdit, .btnDelete {
            padding: 0;
            button {
                background: none;
                border: none;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .btnEdit button {
            color: #ff7b00;
        }
        .btnDelete button {
            color: red;
        }
    }
}

@media(max-width: 990px) {
    .changeLog {
        .row {
            .btnEdit, .btnDelete {
                text-align: center;
                margin: 2vh 0;
            }
        }
    }
}

@media(max-width: 500px) {
    .changeLog {
        width: 100%;
    }
}