.fas.fa-copy,
.fas.fa-share-square {
    color: #1b76d3;
    font-size: 18px;
    margin: 0 8px;
    &:hover {
        cursor: pointer;
    }
}
.colorCrown{
    color: #fbd417;
    margin-left: 5px;
}
.colorCrownTop{
    color: #fb5f17;
    margin-left: 5px;
}