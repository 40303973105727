.tabExpanableRole {
    margin: 0 50px;

    .titleGroup {
        background-color: #8fb8e240;
        border-radius: 10px;
        h5 {
            margin: 5px;
        }
    }
    
    .containSwaggerPatch,
    .containSwaggerPost,
    .containSwaggerGet,
    .containSwaggerDelete,
    .containSwaggerPut {
        margin: 2vh;
        padding: 0 0 2px 0;

        span {
            padding: 0.5vh;
            border-radius: 5px 5px 0 2px;
            color: white !important;
            margin-right: 5px;
            &.boxLine {
                margin: 0;
                color: black !important;
                font-size: 13px;
            }
        }
    }

    .containSwaggerDelete {
        border-bottom: 2px solid #f93e3e;
        .delete {
            background-color: #f93e3e;
        }
    }
    
    .containSwaggerPut {   
        border-bottom: 2px solid #fca130;
        .put {
            background-color: #fca130;
        }
    }
    
    .containSwaggerGet {  
        border-bottom: 2px solid #61affe;
        .get {
            background-color: #61affe;
        }
    }
    
    .containSwaggerPost {  
        border-bottom: 2px solid #49cc90;
        .post {
            background-color: #49cc90;
        }
    }
    
    .containSwaggerPatch{   
        border-bottom: 2px solid #50e3c2;
        .patch {
            background-color: #50e3c2;
        }
    }
}