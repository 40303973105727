.tabPlaylistPlayer {
    margin: 0 60px 10px 60px;
    padding: 15px;
    border: 1px solid #37c3ec;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;

    .row {
        padding: 5px;
        font-size: 13px;
        margin: 0;
        
        &.title {
            font-weight: bold;
            border-bottom: 1px solid grey;
        }

        &.playlistContent {
            .only {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                padding-top: 7px;
                margin: 0;
            }
            &:nth-child(even) {
                background-color: #a3dcec47;
            }
            &:hover {
                background-color: #a3dceca8;
            }
            .iconRow {
                padding: 0;
                .icon {
                    font-size: 20px;
                    margin: 0 10px 0 10px;
                    padding: 0;
                    height: auto;
                    transition: all .2s;
                    &.edit {
                        color: #f99500;
                    }   
                    &.delete {
                        color: red;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .addPlaylist {
        margin-top: 2rem;
        i {
            margin-right: 7px;
        }
    }
}

.tabExpanablePayment {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #5ad05a;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
}

.tabExpanablePromo {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #37c3ec;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
}

.tabExpanableVpnPass {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #ec3737;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
}

.tabExpanableVpnBox {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #ec9737;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
}

.tab-content {
    .title {
        h5{
            font-style: italic;
            margin-top: 18px;
        }
        .btnAdd {
            text-align: center;
            margin-top: 0.5vh !important;
            margin-bottom: 0.5vh;
            i {
                margin-right: 7px;
            }
        }
    }
    .badge-warning {
        margin-bottom: 2vh;
        margin-top: 2vh;
    } 
}
.moreProd {
    text-decoration: underline;
    font-style: italic;
}