.btn-group {
    > .btn {
        background: none;
        color: #838383;
        border: none;
        &::after {
            content: none;
        }
        &:hover {
            background: none !important;
        }
    }

    .dropdown-menu {
        min-width: 15rem;
        .row {
            > div {
                padding: 0;
            }
            .col-9 {
                padding-top: 3px;
                font-size: 14px;
            }
        }
        button {
            margin: 2px 0px 2px 0;
            &:nth-child(odd) {
                background-color: #cfe7ff5b;
            }
            &:hover {
                background-color: #bbddffe0;
            }
            .icon {
                font-size: 20px;
                text-align: center;
            }
            .deletePlayer {
                i {
                    color: red !important;
                }
            }
            .playerLabel {
                color: black !important;
            }
        }
    }
}

.iconPromo {
    color: #5597f3;
    font-size: 20px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconPromoExpired {
    color: #f35555;
    font-size: 20px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconCoupon {
    color: #00d200;
    font-size: 20px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconCouponExpired {
    color: #f35555;
    font-size: 20px;
    margin-right: 3px;
    margin-left: 10px;
}