.btn-group {
    > .btn {
        background: none;
        color: #838383;
        border: none;
        &::after {
            content: none;
        }
        &:hover {
            background: none !important;
        }
    }

    .dropdown-menu {
        min-width: 15rem;
        .row {
            > div {
                padding: 0;
            }
            .col-9 {
                padding-top: 3px;
                font-size: 14px;
            }
        }
        button {
            margin: 2px 0px 2px 0;
            &:nth-child(odd) {
                background-color: #cfe7ff5b;
            }
            &:hover {
                background-color: #bbddffe0;
            }
            .icon {
                font-size: 20px;
                text-align: center;
            }
            .deletePlayer {
                i {
                    color: red !important;
                }
            }
            .playerLabel {
                color: black !important;
            }
        }
    }
}

.tabExpanablePlayer,
.tabExpanableLicence {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #5ad05a;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    h5 {
        font-style: italic;
        margin-bottom: 1rem;
    }
    .addPlaylist {
        padding-right: 5px;
        .btn i {
            margin-right: 5px;
        }
    }
    .title {
        font-weight: bold;
        margin: 5px;
        font-size: 13px;
    }
    .playlistContent {
        margin: 5px;
        font-size: 13px;
        .only {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-top: 7px;
            margin: 0;
        }
        &:nth-child(even) {
            background-color: #a3dcec47;
        }
        &:hover {
            background-color: #a3dceca8;
        }
        .iconRow {
            padding: 0;
            .icon {
                font-size: 20px;
                margin: 0 10px 0 10px;
                padding: 0;
                height: auto;
                transition: all .2s;
                &.edit {
                    color: #f99500;
                }   
                &.delete {
                    color: red;
                }
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .clickForPayment {
        font-size: 18px;
        color: #1b76d3;
        &:hover {
            cursor: pointer;
        }
    }
}

.iconTrial,
.iconOffline {
    color: #f35555;
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconWait {
    color: #ffc626;
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}

.iconLifetime,
.iconOnline {
    color: #00d200;
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}
.iconSub {
    color: #1b76d3;
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;

}
.iconNotSub {
    color: orange;
    font-size: 15px;
    margin-right: 3px;
    margin-left: 10px;
}