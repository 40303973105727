.workerStatus {
    border-radius: 0 0 0 10px;
    border-bottom: 1px solid #1b76d3;
    margin: 1rem 3rem !important;
    
    .title {
        padding: 0;
        color: white;
        border-radius: 8px 8px 0 8px;
        background: #1b76d3;
        
        h5 {
            margin-top: 10px;
            text-align: center;
        }
    }
    
    .badge, .onlyLabel {
        font-size: 1rem;
        margin-left: 10px;
        border: none;
    }

    .btn {
        margin-right: 5px;
    }

    .badge-danger {
        color: #dc3545 !important;
        background: none !important;
    }
    
    .badge-success {
        color: #28a745 !important;
        background: none !important;
    }
}

.contentWorker {
    padding: 0 1rem 0 4rem;
}

.job, .jobTaskService {
    h5 {
        padding: 8px 0 5px 0;
    }
    
    .activeJobTitle {
        i {
            margin-right: 5px;
        }
    }

    li:hover {
        cursor: pointer;
    }

    .MuiTooltip-popper .MuiTooltip-tooltip {
        font-size: 16px !important;
        max-width: 500px;
    }

    .iconStart {
        font-size: 20px;
        color: #1b76d3;
        margin-left: 10px;
    }
}

.tabExpanableJob {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #5ad05a;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
    font-size: 13px !important;

    .badge {
        font-size: 13px !important;
    }

    .marginBorder {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #8080804f;
    }

    .date {
        margin-top: 5px;
        .firstSpan {
            font-weight: bold;
        }
        .secondSpan {
            font-style: italic;
        }
    }
}

@media(max-width: 768px){
    .jobTaskService{
        background-color: whitesmoke;
        padding: 10px;
    }
}