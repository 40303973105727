@keyframes color_change {
    from {
        color: #489bef;
    }

    to {
        color: #0a6bcc;
    }
}

.containerChart {
    .homeChart {
        position: relative;
        border: 2px solid #1b76d3;
        border-radius: 10px;
        margin-top: 2vh;
        margin-bottom: 2vh;
        background-color: white;
        display: inline-block;
        width: 700px;
        height: 420px;
        box-shadow: 5px 5px 5px 0px grey;

        .licenceMonth,
        .licenceDay,
        .vpnChange {
            margin-top: 1rem;
            background-color: rgba(52, 117, 255, 0.5);
            color: whitesmoke;
            border: solid 1px grey;
            border-radius: 5px;
            outline: none;
            float: left;
            margin-left: 1rem;
        }

        .licenceMonth {
            width: 165px;
        }

        .licenceDay {
            width: 55px;
        }

        .vpnChange {
            left: unset;
            right: 10px;
            width: 150px;
        }

        .colorMonth {
            color: black;
        }

        .MuiNativeSelect-select.MuiNativeSelect-select {
            padding-right: 24px;
            padding-left: 9px;
            color: whitesmoke;
            font-weight: bold;
        }

        .loadingInBlock {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .homeChartGains {
        border: 2px solid #1b76d3;
        border-radius: 10px;
        margin-top: 2vh;
        margin-bottom: 2vh;
        background-color: white;
        display: inline-block;
        width: 700px;
        height: 450px;
        box-shadow: 5px 5px 5px 0px grey;
        text-align: center;

        .licenceGains,
        .licencePlatform {
            position: relative;
            width: 335px;
            margin-top: 1vh;
            background-color: rgba(52, 117, 255, 0.5);
            color: whitesmoke;
            border: solid 1px grey;
            border-radius: 5px;
            outline: none;
            padding: 0.5vh 1vh;
        }

        .licenceGains {
            width: 550px;
        }
    }
}

.colorMonth {
    color: black;
}

.tagWidgetLive {
    border-radius: 10px;
    margin: 2%;
    padding: 15px;
    box-shadow: 2px 2px 12px 0px #808080a3;
    background-color: #ffffffc2;

    h5 {
        animation-name: color_change;
        animation-duration: 0.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        text-align: center;

        span {
            font-size: 14px;
            font-weight: 100;
            font-style: italic;
        }
    }

    .label1 {
        font-style: italic;
    }

    .label2 {
        font-weight: bold;
    }

    .icon {
        font-size: 20px;
    }

    .blockForWidget {
        border-radius: 5px;
        padding: 15px;
        background: none;

        .labelTitle {
            font-weight: bold;
            color: red;
            text-align: right;
            font-size: 12px;
        }

        .labelKey {
            font-style: italic;
            text-align: right;
        }

        .labelValue {
            font-weight: bold;
        }

        .row {
            margin: 0;

            >div {
                padding: 2px;
            }
        }
    }
}

.buttonsYear {
    button {
        margin: 0 1%;
    }
}

.emptyStatistics {
    background-color: white;
    box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.20);
    text-align: center;
    padding: 5vh 0;
    border-radius: 15px;
    margin: auto;
    margin-top: 5vh;
    width: 40%;

    img {
        width: 20%;
    }
}

@media(max-width: 768px) {
    .containerWidget {
        font-size: 12px;
        margin-top: 6vh;

        .tagWidgetLive {
            border-radius: 5px;
            margin: 0 0 0 0;
            box-shadow: none;
            background-color: unset;

            .blockForWidget .labelKey {
                text-align: left;
            }
        }
    }

    .containerChart {
        display: block;
        height: 430px;
        border: 2px solid #1b76d3;
        border-radius: 10px;
        margin: 1rem;
        background-color: white;
        overflow-x: scroll;
        overflow-y: hidden;

        .homeChart {
            border-radius: unset;
            margin-bottom: 0;
            margin-right: 0;
            margin-left: 0;
            box-shadow: unset;
            background-color: unset;
            border: none;
        }

        .homeChartGains {
            border: none;
        }
    }

    .tagWidgetLive .containerDeskWidget {

        .widgetCol {
            margin: 1vh 0;
        }

        .hidden {
            display: none;
        }
    }

    .emptyStatistics {
        width: 100%;
    }
}

@media(max-width: 450px) {
    .containerWidget .tagWidgetLive {
        background-color: white;
        box-shadow: 0 0 2px gray;
        border-radius: 0;
        padding: 5rem 0 0 0;
    }
}