.whiteList,
.ivaCountry,
.pricing,
.groups {
    margin: 2rem 0 1rem 0;
    h2 {
        margin-left: 2rem;
    }
}

.contentWhiteList,
.contentCountryList, 
.contentPricingList,
.contentGroups {
    border: 2px solid #1b76d3;
    border-radius: 10px;
    box-shadow: 5px 5px 5px 0px grey;
    margin: 0 15% 6% 15%;
    padding: 3% 5% 3% 5%;
    transition: .2s all;
    background: white;
    position: relative;
    
    .whiteRow {
        border-bottom: 1px solid #80808036;
        min-height: 30px;
        font-size: 13px;
        padding: 5px;
        // border-radius: 13px;
        // margin-bottom: 8px;

        &:nth-child(odd) {
            background-color: #0089ff26;
        }

        .btnEdit, .btnDelete {
            background: none;
            border: none;
            &:hover {
                cursor: pointer;
            }
        }
        .btnEdit {
            color: #ff7b00;
        }
        .btnDelete {
            color: red;
        }
        
        &:hover {
            background-color: rgba(255, 166, 0, 0.699);
            transition: .2s all;
            .btnEdit, .btnDelete {
                color: black;
            }
        }
        .pricingElement{
            margin: 0;
        }
        .pricingElement:hover{
            font-weight: bold;
            cursor: pointer;
        }
    }

    .keyRow {
        font-size: 18px;
        color: red;
        background-color: #8080802e;
        margin-top: 30px;
        
        &:nth-child(1) {
            margin-top: 0;
        }

        i {
            font-size: 20px;
            margin: 8px;
            &:hover {
                cursor: pointer;
            }
        }
    }
        
    .subKeyRow {
        font-size: 16px;
        font-weight: bold;
        .subKeyTitle {
            // text-align: center;
            margin: 0;
            padding: 0;
        }
    }
    
    .btnNewIp {
        background: none;
        border: 1px solid #34cf1f;
        border-radius: 5px;
        color: #34cf1f;
        &:hover {
            background: #34cf1f;
            color: white;
            cursor: pointer;
        }
    }

    .ipAddress, .elementPricing {
        font-size: 14px;
        font-weight: normal;
        padding: 2px 0 2px 0;
        
        .btnEdit, .btnDelete, .btnExclude, .btnInclude {
            background: none;
            border: none;
            &:hover {
                cursor: pointer;
            }
        }
        .btnEdit {
            color: #ff7b00;
        }
        .btnInclude {
            color: rgb(5, 175, 5);
        }
        .btnDelete, .btnExclude {
            color: red;
        }

        &:nth-child(odd) {
            background-color: #0267ff3d;
        }
    }

    .emptyIp {
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
    }

    .reduceText {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.contentPricingList {
    .myAccordion {
        border: none;
        box-shadow: none;
        margin: 0 !important;
        .keyAccordion {
            > div {
                display: block;
                margin: 12px 0 !important;
            }
            .MuiAccordionSummary-content {
                // margin: 12px 0 !important;
            }
        }
        &:before {
            background-color: transparent;
        }
    }

    .MuiAccordionDetails-root {
        display: block;
        padding: 0;
    }
}

.contentCountryList,
.contentGroups {
    .title {
        font-weight: bold;
        border-bottom: 1px solid black;
        padding-bottom: 5px;
    }
}

@media(max-width: 768px){
    .contentWhiteList,
    .contentCountryList,
    .contentGroups {
        border: none;
        border-radius: 5px;
        box-shadow: none;
        margin: 0 0 0 0;
        margin-top: 5vh;
        overflow-x: scroll;
        .title {
            font-size: 15px;
            width: 150vw;
        }
        .whiteRow{
            width: 150vw;
        }
    }
    .contentPricingList{
        margin: unset;
    }
}

@media(max-width: 500px) {
    .whiteList,
    .ivaCountry,
    .pricing,
    .groups {
        margin: 4rem 0 0 0;
    }

    .subKeyRow .elementPricing .styleCol {
        text-align: center;
        margin: 1vh 0;
    }
}