.contentWorker {
    >div {
        height: 100%;

    }

    .activeJob {
        height: 100%;
        border-right: 1px solid #1b76d3;
        padding-right: 15px;

        .activeJobTitle .activeJobButton {
            margin-bottom: 0.5rem;
        }
    }

    .badge {
        font-size: 14px !important;
    }

    .badge-danger {
        color: #dc3545 !important;
        background: none !important;
    }

    .badge-success {
        color: #28a745 !important;
        background: none !important;
    }

    .rdt_TableRow:hover {
        .badge-danger {
            color: white !important;
            text-shadow: 1px 1px 1px #dc3545;
            background: none !important;
        }

        .badge-success {
            color: white !important;
            text-shadow: 1px 1px 1px #28a745;
            background: none !important;
        }
    }
}

@media(max-width: 768px) {
    .contentWorker {
        .activeJob {
            border-right: unset;
            padding-right: 0;

            .activeJobTitle .activeJobButton {
                text-align: center;
            }
        }
    }
}