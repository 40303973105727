.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    display: none;
}

.react-bootstrap-table-pagination-list.col-md-6.col-xs-6.col-sm-6.col-lg-6 {
    display: inline-block;
}

.MuiFormControl-marginNormal {
    margin: 0 !important;
}

.MuiFormControl-root {
    width: 100%;

    .row {
        margin-bottom: 1%;
    }
}

.vpnDesktop {
    .contentFilterSearch {
        width: 15rem;
        position: fixed;
        height: 96vh;
        overflow: auto;
        padding: 2rem 1.5rem;
        background-color: #e5e5e5c4;
        margin: 0 0 0 5px;

        .itemForSearch {
            margin-bottom: 0.7rem;

            .btnAround {
                background-color: #808080ad;
                border-radius: 50px;
                border: none;
                padding: 10px;
                margin: 5px;
                color: white;

                &.selected {
                    background-color: #1572e8;
                }
            }
        }

        .myAccordion {
            margin: 0 !important;
            padding: 0 !important;
            border-radius: 0 !important;
            background: none !important;
            box-shadow: none !important;

            &::before {
                background: none;
            }

            .MuiAccordionSummary-content {
                margin: 12px 0 !important;
            }

            .MuiAccordionSummary-root {
                padding: 0 3px;
                min-height: 48px;
            }

            .MuiAccordionDetails-root {
                padding: 0;
                display: block;
            }
        }
    }

    .contentUser {
        height: unset;
        padding: 1.5rem 0 0 0;
        margin-left: 16rem;
    }

}

.tabExpanablePass {
    margin: 0 60px 10px 60px !important;
    padding: 15px;
    border: 1px solid #5ad05a;
    box-shadow: 1px 1px 11px #80808094;
    border-top: none;
    border-radius: 0px 0px 10px 10px;
}

@media(max-width:768px) {
    .vpnDesktop {
        display: block;
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;

        .contentFilterSearchVpn {
            top: -8.5px !important;
            background-color: #fff;
            border-bottom: 15px solid #d32f2f;
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            margin-bottom: 2vh;
            text-align: center;

            .close {
                display: none;
            }

            .buttonFilters {
                display: inline-block;
                padding: 3px 5px;
                position: relative;
                top: 13px;
                outline: none;
                width: 100%;
                border: none;
                background-color: #d32f2f;
            }

            .typeLicence {
                h5 {
                    margin-top: 5px;
                    padding-top: 15px;
                    border-top: 1px solid #80808057;
                }
            }
        }
    }
}

@media(max-width: 500px) {
    .vpnDesktop {
        .containerTitle {
            display: none;
        }

        .styleCSV {
            margin-top: 6vh;
        }

        .contentFilterSearch {
            width: 100%;
            height: unset;
            position: unset;
            margin: unset;
            overflow-y: unset;
            display: block;
            padding: 4rem 2rem;
            background-color: #e5e5e5c4;
        }

        .contentUser {
            display: block;
            height: unset;
            width: 100%;
            margin-left: unset;
            position: unset;
            overflow-y: unset;
            padding: 2rem 1rem;

            .titleMobile {
                padding: 0 0.5rem;
            }
        }
    }
}