html,
body,
#root,
.appContainer,
.main,
.contentMain {
    min-height: 100vh;
    height: auto;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 15px;
}

.grecaptcha-badge {
    visibility: hidden;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #808080ad;
}

.main {
    width: 100%;
    height: auto;
    overflow: hidden;
    background-color: #f9fbfd;

    .mainLayout {
        padding: 3rem 1rem 0 1rem;
    }

    .contentMain {
        margin-left: 75px;
        transition: all .2s;
        height: 100%;

        &.expand {
            margin-left: 250px;
            transition: all .2s;
        }

        &.onlyLogin {
            margin-left: 0;
        }
    }
}

.mybadge {
    padding: 1px 12px 1px 12px;
    margin: 6px 0px 0px 15px;
    font-size: 17px !important;
    background-color: #008000b0;
    color: white;
    position: absolute;
    border-radius: 10px;
    display: inline-block;
}

.inlineBlock {
    display: inline-block;
}

.removeMargin {
    margin: 0 !important;
}

.addMarginForBtn {
    margin-top: -5rem !important;
}

.typeBox,
.typePass {
    padding: 0.3rem;
    border-radius: 0.5rem;
    color: white !important;

    &.blue {
        background-color: #1b76d3;
    }

    &.orange {
        background-color: #d3611b;
    }
}

.yellow {
    color: rgb(249, 216, 29);
}

.black {
    color: #000000;
}

.orange {
    color: #ec7631;
}

.purple {
    color: #bf5eff;
}

.red {
    color: red;
}

.blue {
    color: #1b76d3;
}

.green {
    color: #51d23a;
}

.cadetblue {
    color: cadetblue;
}

.dropdown {
    button {
        position: relative;
    }

    .dropdown-menu {
        min-width: 15rem;

        .dropdown-item {
            padding: 0.5rem 1rem;

            i {
                margin-right: 0.5rem;
            }
        }
    }

    .dropdown-menu.fixed {
        position: fixed !important;
    }
}

.customSelect label {
    margin-top: 0.5rem;
    margin-left: -1rem;
}

// SIDEBAR CSS

.sidebarMobile,
.sidebarDesktop {
    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, .3);
        z-index: 102;
        display: none;
    }

    .overlay.active {
        display: block;
    }

    .pro-sidebar.md {
        position: relative;
        background-color: white;
        height: 100%;
        width: 270px;
        overflow-y: auto;
        min-width: 270px;
        text-align: left;
        transition: width, left, right, .3s;
        z-index: 102;
        border: none;

        .pro-sidebar-header {
            background-color: #114578;
            height: 50px;

            .logo {
                padding: 0.8rem;
                width: 10rem;
                height: auto;
            }
        }

        .ps-submenu-content {
            background-color: white;
        }

        .ps-menu-button:hover {
            background-color: whitesmoke;
        }

        .version {
            position: absolute;
            right: 1.5rem;
        }
    }

    .pro-sidebar.md {
        position: fixed;
        left: -270px;
    }

    .pro-sidebar.md.toggled {
        position: fixed;
        left: 0;
    }

    .pro-sidebar.md.ps-collapsed {
        width: 80px;
        min-width: 80px;
    }

    .item {
        margin: 2px 5px;
        padding: 10px 1px;
        color: black;

        &.selected {
            background-color: #104f6c;
            border-radius: 5px;
            color: white;
            font-weight: bold;
        }

        &.indent {
            padding-left: 30px;
        }

        .icon {
            display: inline-block;
            width: 50px;
            font-size: 18px;
            text-align: center;
        }

        .text {
            font-size: 16px;
            display: inline-block;

            .fas.fa-ban {
                font-size: 1rem;
                position: absolute;
                right: 1rem;
                padding-top: 0.3rem;
            }
        }
    }

    label {
        padding-left: 30px;
        font-size: 16px;
        color: rgb(0, 170, 255);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        margin-top: 10px;
    }

    .row {
        padding: 0;
        margin: 0;
        margin-bottom: 12px;

        >div {
            padding: 3px;
            margin: 0;

        }

        i {
            font-size: 20px;
        }

        .red {
            color: red;
        }
    }

    >div:nth-child(2) {
        z-index: 120;
    }

    .btn {
        z-index: 990;
        border: 1px solid black;
        position: fixed;
        top: 2%;
        left: 5%;
    }

    .ps-sidebar-container {
        background-color: unset;
    }
}

.sidebarDesktop {
    .pro-sidebar.md {
        left: 0;
        background-color: white;
    }
}

.pad-0 {
    padding: 0 !important;
}


//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// 




// HEADER CSS


.header {
    position: fixed;
    top: 0;
    background-color: #114578;
    height: 50px;
    line-height: 40px;
    padding: 0.5rem 3% 0 2%;
    width: 100%;
    transition: all 0.2s;
    z-index: 2;
    color: white;

    .deactiveForMobile {
        .userArea {
            padding-left: 2rem;

            .infoVersion {
                font-weight: bold;
                padding: 0 1rem 0 0;
            }

            .userMail {
                font-weight: bold;
                padding: 0 1rem;
            }

            .userRole {
                font-style: italic;
                padding: 0 1rem;
            }

            .logo {
                height: 25px;
                margin: -11px 11px 0px -11px;
            }
        }
    }

    .activeForMobile {
        .btnSidebar {
            margin-top: 5px;

            i {
                font-size: 30px;
                color: #ff8800;
            }
        }

        .logo-image {
            height: 40px;
            width: auto;
        }
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@media(max-width: 1100px) {
    .header .btnRight .btnNav.name {
        margin-top: -12px;
        width: 84%;
    }
}

@media(min-width: 769px) {
    .sidebarMobile {
        display: none;
    }

    .activeForMobile {
        display: none !important;
    }

    .header .btnRight {
        text-align: right;
        padding-top: 13px !important;
        padding-right: 45px;
    }
}

@media (max-width: 768px) {

    .btn-success,
    .btn-warning {
        margin: 1rem !important;
    }

    .contentMain {
        margin-left: 0 !important;

        .header {
            height: 60px;

            .deactiveForMobile {
                display: none !important;
            }

            .btnRight {
                padding-top: 0;
            }

            .activeForMobile {
                .userAreaSmart {
                    font-size: 35px;
                }

                .text-right {
                    text-align: left !important;
                }

                .logo-image {
                    height: auto;
                    width: 10rem;
                    margin-left: 1rem;
                }
            }
        }

        .subHeader {
            height: 80px;

            .deactiveForMobile {
                display: none !important;
            }

            .activeForMobile {
                display: block;
            }
        }
    }
}

@media (max-width: 540px) {
    .main .mainLayout {
        padding: 0;
    }
}

@media screen and (max-height: 450px) and (orientation: landscape) {
    .sidebarMobile {
        display: block;
    }
}

@media(max-width: 440px) {
    .sidebarMobile {
        .btn {
            z-index: 990;
            border: unset;
            color: transparent;
            position: absolute;
            top: 2%;
            left: 5%;
        }
    }
}

@media(width: 375px) and (height: 667px) {
    .contentMain {
        .mybadge {
            left: 72vw;
        }
    }
}

@media(max-width: 360px) {
    .contentMain {
        .mybadge {
            left: 72vw;
        }
    }
}

@media(max-width: 320px) {
    .contentMain {
        .mybadge {
            left: 70vw;
        }
    }
}