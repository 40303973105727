@keyframes color_change {
    from {
        color: #489bef;
    }

    to {
        color: #0a6bcc;
    }
}

.loading {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10000;
    background-color: #ffffffb0;
    opacity: 1;
    text-align: center;
    transition: all .2s;
}

.loading,
.loadingBar,
.loadingInBlock {
    height: 100%;
    text-align: center;

    .contentLoading {
        margin-top: 15%;

        /* Spinner di loading */
        .loaderLine {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 65px;
            height: 12px;
            background-color: #e6e6e6;
            border-radius: 5px;
            animation: load ease-in-out 1.8s infinite;

            &:before,
            &:after {
                position: absolute;
                display: block;
                content: "";
                animation: load ease-in-out 1.8s infinite;
                height: 12px;
                border-radius: 5px;
            }

            &:before {
                top: -25px;
                left: 10px;
                width: 65px;
                background-color: #1860a2;
            }

            &:after {
                bottom: -25px;
                width: 65px;
                background-color: #5699d2;
            }
        }

        .label {
            position: absolute;
            top: 60%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @keyframes load {
            0% {
                transform: translateX(30px);
            }

            50% {
                transform: translateX(-60px);
            }

            100% {
                transform: translateX(30px);
            }
        }
    }
}

@media(max-width: 500px) {
    .loadingInBlock .contentLoading img {
        width: 700px;
        height: 370px;
    }
}